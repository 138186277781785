<!--
 * @Author: lanxx
 * @Date: 2021-11-11 09:31:38
 * @LastEditTime: 2022-05-28 09:59:27
 * @LastEditors: lanxx
 * @Description: Do not edit
 * @FilePath: \prosperity-garden-ui\src\views\county.vue
-->
<template>
  <div
    class="container"
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="main">
      <top-header></top-header>
      <div class="content">
        <div class="top">
          <div class="left">
            <title2 name="全市各镇、街道概况" style="margin: 0 0 24px"></title2>
            <el-table :data="titleData" style="width: 100%">
                <el-table-column prop="" label="镇、街道" width="208" />
                <el-table-column prop="" label="结对户" width="112" />
                <el-table-column prop="" label="结对人数" />
                <el-table-column prop="" label="" width="112" />
              </el-table>
            <div class="lBox">
              <vue-seamless-scroll
              :data="townOwnerList"
              :class-option="defaultOption1"
            >
              <el-table :data="townOwnerList" style="width: 100%">
                <el-table-column prop="town" label="" width="208" />
                <el-table-column prop="pairedHouseNum" label="" width="112">
                  <template slot-scope="scope">
                    {{scope.row.pairedHouseNum}} 户
                  </template>
                </el-table-column>
                <el-table-column prop="pairedPeopleNum" label="">
                  <template slot-scope="scope">
                    <el-progress
                      :stroke-width="16"
                      :percentage="Number((scope.row.pairedPeopleNum*100/townOwnerNum).toFixed(0))"
                      :show-text="false"
                      ></el-progress
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="pairedPeopleNum" label="" width="112">
                  <template slot-scope="scope">
                    {{scope.row.pairedPeopleNum}} 人
                  </template>
                </el-table-column>
              </el-table>
            </vue-seamless-scroll>
            </div>
          </div>
          <div class="right">
            <title2 name="菜园结对户类别" style="margin: 0 0 24px"></title2>
            <div class="pieNotes">
              <div>
                <img src="../assets/images/icon／低保@2x.png" alt="">
                <div v-if="pieData[1]">
                  <span>{{pieData[1].name}}</span>
                  <span class="num">{{pieData[1].value}}</span>
                </div>
              </div>
              <div>
                <img src="../assets/images/icon／残疾@2x.png" alt="">
                <div v-if="pieData[1]">
                  <span>{{pieData[0].name}}</span>
                  <span class="num">{{pieData[0].value}}</span>
                </div>
              </div>
            </div>
            <div class="pieBox">
              <div class="pie" ref="pie"></div>
              <div class="totle" v-if="pieData[1]"><div class="text">总户数</div><div>{{Number(pieData[1].value)+Number(pieData[0].value)}}</div></div>
            </div>
            

            <div class="pieList" v-for="(item, index) in pieData" :key="index">
              <div class="text">
                <div :class="'dian' + index" />
                {{ item.name }}
              </div>
              <div class="money">{{ (Number(item.value)*100/(Number(pieData[0].value) + Number(pieData[1].value))).toFixed(0) }} %</div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <title2 name="各菜园主人详情" style="margin: 30px 0 24px"></title2>
          <div class="shellBox">
          <div class="shell">
            <vue-seamless-scroll
              :data="townList"
              :class-option="defaultOption2"
            >
              <div class="vegetable">
                <div class="box" v-for="item in townList" :key="item.orgId">
                  <div class="title">{{ item.gardenName }}</div>
                  <div class="heng" />
                  <div class="lists">
                    <div>
                      <span class="text">主人姓名</span
                      ><span class="money">{{ nameDesensitization(item.displayName) }}</span>
                    </div>
                    <div>
                      <span class="text">所属镇街</span
                      ><span class="money">{{ item.town }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </vue-seamless-scroll>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { $getJson } from "@/utils/fetch";
// import { splitToChunks } from "@/utils/index";
// const cellSize = [0, 0];
export default {
  name: "county",
  data() {
    return {
      loading: true,
      pieData: [
        {
          "name": "残疾人",
          "value": "276"
        },
        {
          "name": "普通低收入农户",
          "value": "357"
        }
      ],
      townList: [],
      townOwnerList: [],
      townOwnerNum: 0,
    };
  },
  computed: {
    optionPie() {
      return {
        titleData:[

        ],
        tooltip: {
          trigger: "item",
        },
        color: ['#E97B35', '#00AAFF', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
        grid: {
          // 让图表占满容器
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
        },
        series: [
          {
            name: "已分配收入构成",
            type: "pie",
            radius: ["55%", "90%"],
            roseType: 'area',
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },

            data: this.pieData,
          },
        ],
      };
    },
    defaultOption1() {
      return {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        openTouch: false,
      };
    },
    defaultOption2() {
      return {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        openTouch: false,
      };
    },
  },
  mounted() {
    this.refresh();
  },
  methods: {
    refresh() {
      //this.pieData = [];
      // 图形数据处理
      this.$api.bigdata.owner("330481").then((res) => {
        this.townOwnerList = res.data.townOwnerList
        res.data.townOwnerList.map(item => {
          //this.townOwnerList.push({name: Object.keys(item)[0], value: Object.values(item)[0]})
          this.townOwnerNum += Number(item.pairedPeopleNum)
        })
        console.log(this.townOwnerNum )
        this.townList = res.data.householdInfoVOList
        //this.pieData = res.data.lowIncomeFarmersList
        // 柱状图
        const pie = this.$echarts.init(this.$refs.pie);
        pie.setOption(this.optionPie);
        this.loading = false
      });
    },
    nameDesensitization(str) {
            if(null == str || str == undefined || str.length == 0){
              return ''
            }
            if(str.length == 1) {
              return str
            }
            if(str.length == 2){
                return str.substring(0,1)+'*' //截取name 字符串截取第一个字符，
            }
            
            let starStr = ''
            for (let index = 0; index < str.length - 1; index++) {
              starStr += "*"
            }
            return str.substring(0, 1) + starStr
        },
  },
};
</script>

<style scoped lang="scss">
.exclusion {
  width: 24px;
  height: 24px;
  background: #0084c7;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.exclusion1 {
  background: #fe6b00;
}
.exclusion2 {
  background: #fec700;
}
.exclusion3 {
  background: #64c1b6;
}
::v-deep .el-progress-bar__outer {
  background: rgba(28, 93, 180, 0.6);
}
::v-deep .el-progress-bar__inner {
  background: linear-gradient(90deg, #11d2bb 0%, #1eaeff 100%);
}
::v-deep .el-table--fit {
  background: none;
}
::v-deep .el-table tr {
  background-color: transparent;
}
::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td.el-table__cell {
  background-color: transparent;
}
::v-deep .el-table th.el-table__cell {
  background-color: transparent;
}
::v-deep .el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
::v-deep .el-table::before {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0px;
}
::v-deep .el-table td {
  border-bottom: 0px solid #dfe6ec !important;
}
::v-deep .el-table .cell {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
}
::v-deep .el-table td.el-table__cell div {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.pieNotes {
  display: flex;
  margin: 70px 0 50px;
  div {
    flex: 1;
    display: flex;
    img {
      width: 45px;
      height: 45px;
      margin-right: 10px;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      span {
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255,255,255,0.8000);
        letter-spacing: 1px;
      }
      .num {
        font-size: 28px;
        font-family: DIN Medium-Regular, DIN Medium;
        font-weight: 400;
        color: #FFFFFF;
        letter-spacing: 1px;
        margin-top: 5px;
      }
    }
  }
}
.pieBox {
  position: relative;
  width: 100%;
  height: 300px;
}
.pie {
  width: 100%;
  height: 300px;
}
.totle {
  width: 124px;
  height: 124px;
  background: rgba(180,182,235,0.2000);
  opacity: 1;
  border-radius: 62px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: 40px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  letter-spacing: 1px;

  .text {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    letter-spacing: 1px;
    margin-bottom: 5px;
  }
}
.pieList {
  display: flex;
  justify-content: space-between;
  padding: 4px 24px;
  margin-top: 12px;
  .text {
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      opacity: 1;
      margin-right: 5px;
    }
    .dian0 {
      background: #E97B35;
    }
    .dian1 {
      background: #00AAFF;
    }
    .dian2 {
      background: #fac858;
    }
    .dian3 {
      background: #ee6666;
    }
    .dian4 {
      background: #73c0de;
    }
  }
  .money {
    font-size: 14px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #ffffff;
  }
}
.shellBox {
  background: url("../assets/images/菜园主人／列表／bg@2x.png") no-repeat;
  background-size: 100%;
  display: flex;
  justify-content: center;
  border-radius: 8px 8px 8px 8px;
}
.shell {
  width: 1731px;
  height: 209px;
  overflow: hidden;
}
.vegetable {
  width: 100%;
  height: 209px;
  display: flex;
  align-items: center;
  .box {
    width: 301px;
    height: 141px;
    background: url("../assets/images/菜园主人／列表／卡片bg@2x.png") no-repeat;
    background-size: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin: 0 10px;
    .title {
      font-size: 18px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: bold;
      color: #ffffff;
      line-height: 21px;
      letter-spacing: 1px;
    }
    .heng {
      width: 16px;
      height: 4px;
      background: #00aaff;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin: 8px 0 16px;
    }
    .lists {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      padding-bottom: 10px;
      div {
        display: flex;
        justify-content: space-between;
      }
      .text {
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        letter-spacing: 1px;
      }
      .money {
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        letter-spacing: 1px;
      }
    }
  }
}
.main {
  .content {
    display: flex;
    flex-direction: column;
    padding: 24px 46px;
    .top {
      display: flex;
    }
    .left {
      position: relative;
      flex: 1;
      width: 1338px;
      // margin-right: 35px;
      // padding-right: 35px;
      .top-total {
        display: flex;
        .item {
          display: flex;
          align-items: center;
          width: 441px;
          height: 115px;
          background: url("../assets/images/card@2x.png");
          background-size: 100% 100%;
          font-size: 0;
          &.addbody {
            width: 441px;
          }
          & + .item {
            margin-left: 25px;
          }
          &:nth-child(2) {
            background-image: url("../assets/images/card@2x (1).png");
            .num,
            .unit {
              color: #00d5ee;
            }
          }
          &:nth-child(3) {
            background-image: url("../assets/images/card@2x (2).png");
            .num,
            .unit {
              color: #f2b800;
            }
          }
          .icon {
            width: 60px;
            margin: 0 0 0 30px;
            img {
              width: 100%;
            }
          }
          .body {
            padding: 0 20px;
            transform: translateX(170px);
            & + .body {
              border-left: 1px solid #eef8ff;
            }
          }
          .num {
            font-size: 32px;
            font-weight: 600;
            font-style: italic;
            color: #00d387;
          }
          .unit {
            font-size: 16px;
            margin-left: 3px;
            color: #00d387;
          }
          .name {
            font-size: 16px;
            line-height: 30px;
          }
        }
      }
      .year-total {
        margin: 30px 0;
        width: 239px;
        height: 427px;
        background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/year.png);
        background-size: 100% 100%;
        .header {
          height: 70px;
          line-height: 70px;
          text-align: center;
          font-size: 24px;
        }
        .list {
          .round-rotate {
            margin: 10px 0;
          }
        }
      }
      .county-total {
        font-size: 18px;
        margin-top: 30px;
        border-top: 1px solid rgba(0, 149, 255, 0.5);
        border-left: 1px solid rgba(0, 149, 255, 0.5);
        table {
          width: 100%;
          tr {
            &.area {
              td {
                background: rgba(0, 63, 152, 1);
                text-shadow: 0 0 2px #007ffc;
              }
            }
            &.num {
              td {
                &:nth-child(odd) {
                  .line {
                    background-image: linear-gradient(
                      0deg,
                      rgba(247, 181, 0, 0) 0%,
                      rgba(247, 181, 0, 0.75) 100%
                    );
                    border-color: rgba(#f7b500, 0.7);
                  }
                }
              }
            }
            td {
              text-align: center;
              vertical-align: bottom;
              padding: 5px 0;
              line-height: 42px;
              border-right: 1px solid rgba(0, 149, 255, 0.5);
              border-bottom: 1px solid rgba(0, 149, 255, 0.5);
              background: rgba(0, 63, 152, 0.4);
              &.header {
                color: #00aaff;
              }
            }
          }
        }
        .bar {
          position: relative;
          height: 115px;
          width: 70%;
          margin: 0 auto;
          .text {
            position: absolute;
            width: 100%;
            bottom: 0;
          }
          .line {
            position: absolute;
            width: 100%;
            bottom: 0;
            height: 50%;
            background-image: linear-gradient(
              0deg,
              rgba(0, 199, 255, 0) 0%,
              rgba(0, 149, 255, 0.75) 100%
            );
            border: 2px solid rgba(#00afff, 0.7);
            border-bottom: none;
            transition: height 0.3s;
          }
        }
      }
      .map {
        position: absolute;
        top: 80px;
        left: 250px;
        right: 0;
        bottom: 250px;
      }
      .light-level {
        position: absolute;
        left: 50%;
        transform: translateX(-25%);
        top: 540px;
        font-size: 0;
        text-align: center;
        li {
          margin: 0 8px;
          display: inline-block;
          cursor: pointer;
          width: 160px;
          height: 56px;
          line-height: 56px;
          background-size: 100% 100%;
          &:nth-child(1) {
            background-image: url(../assets/images/green_bg.png);
          }
          &:nth-child(2) {
            background-image: url(../assets/images/yellow_bg.png);
          }
          &:nth-child(3) {
            background-image: url(../assets/images/red_bg.png);
          }
          img {
            display: inline-block;
            width: 40px;
            vertical-align: top;
            margin-top: 7px;
          }
          span {
            display: inline-block;
            vertical-align: top;
            &.num {
              font-size: 18px;
              font-style: italic;
              margin: 0 2px 0 6px;
            }
            &.unit {
              font-size: 14px;
            }
          }
        }
      }
    }
    .right {
      width: 440px;
      margin-left: 35px;
      ::v-deep .el-carousel__container {
        height: 234px;
        margin: 21px 0;
      }
      .plant-list {
        overflow: hidden;
        li {
          display: flex;
          padding: 0 16px;
          height: 54px;
          line-height: 54px;
          font-size: 18px;
          background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/plant.png);
          background-size: 100% 100%;
          & + li {
            margin-top: 6px;
          }
          .name {
            width: 130px;
            text-shadow: 0 0 2px #007ffc;
          }
          .num {
            width: 110px;
            color: #00aaff;
          }
          .bar {
            flex: 1;
            .line {
              margin-top: 13px;
              height: 30px;
              width: 50%;
              background-color: #007ffc;
            }
          }
        }
      }
      .garden-list {
        margin: 10px 0 0;
        &.garden-list-body {
          margin: 0;
          height: 400px;
          overflow: hidden;
          li {
            cursor: pointer;
          }
        }
        &.income {
          margin: 0 0 22px 0;
        }
        li {
          font-size: 0;
          &:nth-child(even) {
            background: rgba(0, 63, 152, 0.3);
          }
          span {
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            letter-spacing: 1px;
            display: inline-block;
            width: 33%;
            font-size: 16px;
            text-align: center;
            line-height: 46px;
            &:nth-child(2) {
              color: #fec700;
            }
            &:nth-child(3) {
              color: #65d05f;
            }
            &:nth-child(4) {
              color: #0095ff;
            }
          }
          border-bottom: 1px solid rgba(0, 149, 255, 0.5);
        }
      }
    }
  }
}
.lBox {
  height: 550px;
  overflow: hidden;
}
::v-deep .el-table__empty-block {
  display: none;
}
</style>